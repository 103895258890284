<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <!-- <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" @nodeExpand="onNodeExpand"
                v-model:expandedKeys="expandedKey" v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div> -->
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{categoryName}}</h5>
                <DataTable :value="products" v-model:filters="filters1" v-model:expandedRows="expandedRows"
                    v-model:selection="selectedRow" selectionMode="multiple" :metaKeySelection="false"
                    :filters="filters1" filterDisplay="menu" dataKey="id" :globalFilterFields="['name','mpn','sku']"
                    scrollable :tableStyle="styleHeight" :scrollHeight="sHeight" responsiveLayout="scroll" :lazy="true"
                    :loading="loading" :paginator="true" :rows="pagesize" :virtualScrollerOptions="vsOptionFun()"
                    :rowsPerPageOptions="[50, 100, 200, 500]" :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="grid">
                            <div class="col-6">
                                <div class="text-left">
                                    <Button label="目录维护" v-if="productdirectoryitems.length>0" icon="pi pi-plus"
                                        @click="openproductDirectory()" class="p-button-outlined mb-2" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <SplitButton label="产品" v-if="productitems.length>0" icon="pi pi-plus"
                                        :model="productitems" class="p-button-outlined mb-2" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" v-if="showProductTag" icon="pi pi-external-link"
                                        label="产品分类标签" @click="openProductTag()"
                                        class="p-button-outlined mb-2" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" v-if="showcalculateProductCost" icon="pi pi-external-link"
                                        label="成本核算" @click="opencalculateProductCost()"
                                        class="p-button-outlined mb-2" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" v-if="showcalculateProductCost" icon="pi pi-external-link"
                                        label="成本核算导出" @click="exportProductCost()" class="p-button-outlined mb-2" />
                                </div>
                            </div>
                            <div class="col-1">
                            </div>
                            <div class="col-5">
                                <div class="grid">
                                    <div class="col-4">
                                        <CalfTreeDropdown v-model:selectedValue="productselectdirectoryid"
                                            dictObjName="productdirectorydict" placeholder="选择产品目录"
                                            ref="productdirectory" id="productdirectory" style="width:100%" />
                                    </div>
                                    <div class="col-5">
                                        <InputText v-model="filters1['global'].value" placeholder="输入产品名称或MPN或SKU模糊查询"
                                            style="width:100%" />
                                    </div>
                                    <div class="col-3">
                                        <Button type="button" icon="pi pi-filter-slash" label="清空"
                                            @click="clearFilter1();"
                                            class="p-button-outlined mb-2" />&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button type="button" icon="pi pi-search" label="查询" @click="customFilter();"
                                            class="p-button-outlined mb-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column frozen header="&nbsp;&nbsp;&nbsp;&nbsp;" bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;">
                        <template #body="slotProps">
                            <button v-show="slotProps.data['producttype']=='2'"
                                @click="expandertoggle($event,slotProps.data)" aria-haspopup="true"
                                :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span
                                    class="p-row-toggler-icon pi pi-chevron-right"></span><span
                                    class="p-ink"></span></button>
                            <Button v-show="slotProps.data['producttype']!='2'" label="&nbsp;" disabled="disabled"
                                class="p-button-link" />
                            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" :id="slotProps.data['id']"
                                style="width:650px" :breakpoints="{'960px': '75vw'}">
                                <TabView>
                                    <TabPanel header="配件信息">
                                        <DataTable :value="productlistparts" responsiveLayout="scroll">
                                            <Column field="mpn" style="min-width:200px" header="平台MPN"></Column>
                                            <Column field="sku" style="min-width:200px" header="工厂SKU"></Column>
                                            <Column field="name" style="min-width:180px" header="配件名称">
                                                <template #body="slotProps">
                                                    <label class="oneline"
                                                        v-tooltip.top="{ value: slotProps.data.name, disabled: false }">{{slotProps.data.name}}</label>
                                                </template>
                                            </Column>
                                            <Column field="matching" style="min-width:200px" header="配比"></Column>
                                        </DataTable>
                                    </TabPanel>
                                </TabView>
                            </OverlayPanel>
                        </template>
                    </Column>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;">
                    </Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :showFilterMenu="col.showFilterMenu" :showFilterMatchModes="col.showFilterMenu"
                        :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline || col.isimage" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.mpn" @click="openProduct(slotProps.data)"
                                class="p-button-link" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                            <Image v-if="col.isimage" :src="getFilePatch(slotProps.data[col.field])" width="50"
                                preview />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :isReadOnly="readOnly" :childNewDataConfig="childNewDataConfig"
                :childDelDataConfig="childDelDataConfig" :CellEditCompleteMethod="cellEditComplete"
                :noLoadChild="noLoadChild" :inputConfig="inputConfig1"
                :inputdropSelectAfterMethod="inputdropSelectAfter" :pageJsonConfig="pageJsonConfig"
                :loadDataAfterMethod="loadDataAfter" :saveBeforeMethod="saveBefore" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog :header="costsubject" id="costDialog" v-model:visible="displayMaximizable2" :style="{width: '70vw'}"
        :maximizable="false" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage2" :currentRow="currentRow2" :isReadOnly="false"
                :childNewDataConfig="childNewDataConfig2" :childDelDataConfig="childDelDataConfig2"
                :CellEditCompleteMethod="cellEditComplete2" :inputConfig="inputConfig2"
                :pageJsonConfig="pageJsonConfig2" :loadDataAfterMethod="loadDataAfter2" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable2" />
                <Button v-if="!readOnly" label="保存参数" icon="pi pi-check" @click="saveProductCost" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog :header="foldTitle" v-model:visible="displayRootFold" :style="{width: '40vw'}" :maximizable="false"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <InputText id="rootName" v-model="rootFoldName" type="text" />
                    <label for="rootName">根目录名称</label>
                </span>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="取消" icon="pi pi-power-off" class="ml-auto" @click="closeRootFold" />
                <Button label="确认" icon="pi pi-check" :loading="butLoading" @click="saveRootFold" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog :header="foldTitle" v-model:visible="displayChildFold" :style="{width: '40vw'}" :maximizable="false"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <InputText id="foldName" v-model="childFoldName" type="text" />
                    <label for="foldName">目录名称</label>
                </span>
            </div>
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <TreeSelect :options="nodes" @nodeSelect="onCreateFoldNodeSelect" v-model="childFoldParentSelect"
                        id="parentName"></TreeSelect>
                    <label for="parentName">父目录</label>
                </span>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="取消" icon="pi pi-power-off" class="ml-auto" @click="closeChildFold" />
                <Button label="确认" icon="pi pi-check" :loading="butLoading" @click="saveChildFold" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog :header="foldTitle" v-model:visible="displayAuthFold" :style="{width: '60vw'}" :maximizable="false"
        :modal="true">
        <Toolbar class="mb-2">
            <template #start>
                <Button label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                    @click="createAuthSet" />
                <Button label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                    @click="delAuthSet" />
            </template>
        </Toolbar>
        <DataTable v-model:value="productauth" v-model:selection="selectedProductAuthRow" selectionMode="multiple"
            editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table"
            responsiveLayout="scroll">
            <Column selectionMode="multiple" frozen headerStyle="width: 3em"></Column>
            <Column field="authowner" header="姓名" frozen key="authowner" style="width:20%">
                <template #editor="{ data }">
                    <CalfInputDropdown v-model:selectedTxt="data['authowner']"
                        v-model:selectedValue="data['authownerid']" id="productauthowner" dictObjName="userdict"
                        :loadAll="true" :canEditable="false" />
                </template>
            </Column>
            <Column field="includechild" header="应用子目录" key="includechild" style="width:16%">
                <template #header>
                    <Checkbox v-model="allIncludechild" @change="productauthSetChange('includechild')" :binary="true" />
                </template>
                <template #editor="{ data }">
                    <Checkbox v-model="data['includechild']" :binary="true" />
                </template>
                <template #body="slotProps">
                    {{formatData(slotProps.data['includechild'])}}
                </template>
            </Column>
            <Column field="canquery" header="可查看" key="canquery" style="width:16%">
                <template #header>
                    <Checkbox v-model="allCanquery" @change="productauthSetChange('canquery')" :binary="true" />
                </template>
                <template #editor="{ data }">
                    <Checkbox v-model="data['canquery']" :binary="true" />
                </template>
                <template #body="slotProps">
                    {{formatData(slotProps.data['canquery'])}}
                </template>
            </Column>
            <Column field="canedit" header="可修改" key="canedit" style="width:16%">
                <template #header>
                    <Checkbox v-model="allCanedit" @change="productauthSetChange('canedit')" :binary="true" />
                </template>
                <template #editor="{ data }">
                    <Checkbox v-model="data['canedit']" :binary="true" />
                </template>
                <template #body="slotProps">
                    {{formatData(slotProps.data['canedit'])}}
                </template>
            </Column>
            <Column field="cannew" header="可新增" key="cannew" style="width:16%">
                <template #header>
                    <Checkbox v-model="allCannew" @change="productauthSetChange('cannew')" :binary="true" />
                </template>
                <template #editor="{ data }">
                    <Checkbox v-model="data['cannew']" :binary="true" />
                </template>
                <template #body="slotProps">
                    {{formatData(slotProps.data['cannew'])}}
                </template>
            </Column>
            <Column field="candel" header="可删除" key="candel" style="width:16%">
                <template #header>
                    <Checkbox v-model="allCandel" @change="productauthSetChange('candel')" :binary="true" />
                </template>
                <template #editor="{ data }">
                    <Checkbox v-model="data['candel']" :binary="true" />
                </template>
                <template #body="slotProps">
                    {{formatData(slotProps.data['candel'])}}
                </template>
            </Column>
        </DataTable>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="取消" icon="pi pi-power-off" class="ml-auto" @click="closeAuthFold" />
                <Button label="确认" icon="pi pi-check" :loading="butLoading" @click="saveAuthFold" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="数据导出" v-model:visible="importExcelDisplay" :style="{width: '30vw'}" :maximizable="false"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div v-if="importFinish" class="field col-12 md:col-12">
                <Button label="数据处理完成,请点击下载" @click="getExcelFilePatch" class="p-button-link" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <ProgressBar mode="indeterminate" style="height: .5em" />
            </div>
            <div v-if="!importFinish" class="field col-12 md:col-12">
                <label>数据处理中....</label>
            </div>
        </div>
    </Dialog>
    <Dialog header="标签分类" v-model:visible="productTagShow" :style="{width: '90vw'}" :modal="true">
        <DataTable :value="allproducttags" v-model:selection="selectedProductTagRow" :metaKeySelection="false"
            dataKey="id" scrollable scrollHeight="450px" responsiveLayout="scroll" :loading="loading" :paginator="true"
            :lazy="true" :rows="100" :virtualScrollerOptions="{ itemSize: 40 }" :totalRecords="allProductRecords"
            :first="allProductFirst"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
            @page="listProductTag($event)">
            <template #header>
                <div class="grid">
                    <div class="col-8">
                        <div class="text-left">
                            <InputText v-model="queySearchKey" placeholder="输入SKU" />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <CalfMultiSelect v-model:selectedValue="queySearchTag" :loadAll="true"
                                dictObjName="dictionary" dictCategories="ProductTag" />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="text-right">
                            <Button type="button" label="查询" class="mb-2"
                                @click="queyProductTag();" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button type="button" label="新建" class="mb-2"
                                @click="createProductTag();" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button type="button" label="删除" class="mb-2"
                                @click="delProductTag();" />&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </template>
            <Column selectionMode="multiple" bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
            </Column>
            <Column field="productname" bodyStyle="width:30%;" headerStyle="width:30%;" header="产品名称">
            </Column>
            <Column field="mpn" bodyStyle="width:20%;" headerStyle="width:20%;" header="MPN">
            </Column>
            <Column field="sku" bodyStyle="width:20%" headerStyle="width:20%;" header="SKU">
            </Column>
            <Column field="tagname" bodyStyle="width:20%" headerStyle="width:20%;" header="标签">
            </Column>
        </DataTable>
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closeProductTag" />
        </template>
    </Dialog>
    <Dialog header="标签详细" v-model:visible="productTagDetailShow" :style="{width: '40vw'}" :maximizable="false"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <CalfInputDropdown :loadAll="true" v-model:selectedValue="productTagDetail.productid"
                        v-model:selectedTxt="productTagDetail.sku" id="productsku" :canEditable="true"
                        dictObjName="dictionary^ProductSku" />
                    <label for="productsku">SKU</label>
                </span>
            </div>
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <CalfDropdown :showClear="true" :loadAll="true" v-model:selectedValue="productTagDetail.tagid"
                        v-model:selectedTxt="productTagDetail.tagname" id="productTag"
                        dictObjName="dictionary^ProductTag" />
                    <label for="productTag">标签</label>
                </span>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="取消" icon="pi pi-power-off" class="ml-auto" @click="closeproductTagDetail" />
                <Button label="确认" icon="pi pi-check" :loading="butLoading" @click="saveproductTagDetail" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="目录维护" v-model:visible="productDirectoryShow" :style="{width: '40vw'}" :maximizable="false"
        :modal="true">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" @nodeExpand="onNodeExpand"
                v-model:expandedKeys="expandedKey" v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeproductDirectory" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import Image from 'primevue/image';
    import CalfTreeDropdown from '../../../components/CalfTreeDropdown.vue';
    import CalfInputDropdown from '../../../components/CalfInputDropdown.vue';
    import CalfDropdown from '../../../components/CalfDropdown.vue';
    import CalfMultiSelect from '../../../components/CalfMultiSelect.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import productPageJson from "@/data/productConfig.json";
    // import productSuitPageJson from "@/data/productSuitConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '687';
            const listcolumns = ref();
            return {
                confirm,
                currentMenuId,
                listcolumns
            };
        },
        data() {
            return {
                uploadurl: '',
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                queySearchTag: [],
                queySearchKey: '',
                allProductFirst: 0,
                allProductRecords: 0,
                selectedProductTagRow: [],
                allproducttags: [],
                productTagShow: false,
                productTagDetailShow: false,
                productTagDetail: {},
                pageJsonConfig: {},
                pageJsonConfig2: {},
                productSuitPageJson: {},
                productPageJson: {},
                recordsubject: '',
                costsubject: '',
                foldTitle: '',
                readOnly: false,
                butLoading: false,
                displayMaximizable: false,
                displayMaximizable2: false,
                displayRootFold: false,
                displayChildFold: false,
                displayAuthFold: false,
                allCanquery: false,
                allCanedit: false,
                allCannew: false,
                allCandel: false,
                allIncludechild: false,
                showcalculateProductCost: false,
                showProductTag: false,
                categoryName: '产品列表',
                expandedRows: ref([]),
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                selectedRow: null,
                selectedProductAuthRow: null,
                expandedKey: null,
                selectedKey: {},
                currentTeeNodeId: '',
                rootFoldName: '',
                childFoldName: '',
                foldId: '',
                currentNode: null,
                childFoldParentSelect: null,
                childFoldParentNode: null,
                noLoadChild: [],
                childNewDataConfig: {
                    images: true,
                    parts: false,
                    craft: true,
                },
                childNewDataConfig2: {
                    costfixarg: false,
                },
                childDelDataConfig: {
                    parts: true,
                    craft: true,
                },
                childDelDataConfig2: {
                    costfixarg: false,
                },
                inputConfig1: {
                    pricefun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.productpriceBlur(allmainelements, allchildelements);
                    },
                    boxnumberfun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.productpriceBlur(allmainelements, allchildelements);
                    },
                    lengthfun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.productlengthBlur(allmainelements, allchildelements);
                    },
                    widthfun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.productlengthBlur(allmainelements, allchildelements);
                    },
                    heightfun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.productlengthBlur(allmainelements, allchildelements);
                    },
                    packingsizefun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.productPackingSizeBlur(allmainelements, allchildelements);
                    },
                },
                inputConfig2: {
                    b2bpricefun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.b2bpriceBlur(allmainelements, allchildelements);
                    },
                    expressagefun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.expressageBlur(allmainelements, allchildelements);
                    },
                    amazonpricefun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.amazonpriceBlur(allmainelements, allchildelements);
                    },
                    alloceanfreightfun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.alloceanfreightBlur(allmainelements, allchildelements);
                    },
                    purchasepricefun: (allmainelements, allchildelements, v_value) => {
                        console.log(v_value);
                        this.purchasepriceBlur(allmainelements, allchildelements);
                    },
                },
                pagesize: 50,
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                productType: 1,
                currentRow: {
                    bindpage: 'product',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                currentRow2: {
                    bindpage: 'productcost',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                products: [],
                productauth: [],
                delproductauth: [],
                loading: false,
                nodes: [],
                items: [],
                productlistparts: [],
                excelFilePatch: '',
                importFinish: false,
                importExcelDisplay: false,
                productDirectoryShow: false,
                productselectdirectoryid: '',
                productdirectoryitems: [],
                productitems: [],
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.loadUserAuth();
                this.configService.getproductCost(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.pageJsonConfig2 = data.page;
                });
                this.configService.getproduct(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.productpage;
                    this.productPageJson = data.productpage;
                    this.productSuitPageJson = data.productsuitpage;
                    this.productselectdirectoryid = '';
                    this.productDirectoryShow = false;
                    this.productdirectoryitems = [];
                    this.productitems = [];
                    this.loadAllUser();
                    this.initFilters1();
                    this.loadMenuTree('-1');
                    this.loadData(1);
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                    var directoryObj = {
                        label: '目录',
                        icon: 'pi pi-fw pi-folder',
                        items: [{
                            label: '新建根目录',
                            icon: 'pi pi-fw pi-folder',
                            command: () => {
                                this.createRootFolder();
                            }
                        }, {
                            separator: true
                        }],
                    };
                    this.productdirectoryitems.push({
                        label: '新建根目录',
                        icon: 'pi pi-fw pi-folder',
                        command: () => {
                            this.createRootFolder();
                        }
                    });
                    this.items.push(directoryObj);
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            openproductDirectory() {
                this.productDirectoryShow = true;
            },
            closeproductDirectory() {
                this.productDirectoryShow = false;
            },
            initFilters1() {
                this.productselectdirectoryid = '';
                this.$refs.productdirectory.clearSelect();
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.selectedRow = [];
                this.initFilters1();
                this.loadData(1);
            },
            customFilter() {
                this.selectedRow = [];
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            closeProductTag() {
                this.productTagShow = false;
            },
            openProductTag() {
                this.productTagDetail = {
                    id: '',
                    sku: '',
                    mpn: '',
                    tagname: '',
                    tagid: ''
                };
                this.productTagDetailShow = false;
                this.queySearchKey = '';
                this.queySearchTag = [];
                this.loadProductTagData(1);
                this.productTagShow = true;
            },
            queyProductTag() {
                this.loadProductTagData(1);
            },
            listProductTag(event) {
                var page = event.page;
                this.loadProductTagData(page + 1);
            },
            createProductTag() {
                this.productTagDetail = {
                    id: '',
                    productid: '',
                    sku: '',
                    mpn: '',
                    tagname: '',
                    tagid: ''
                };
                this.productTagDetailShow = true;
            },
            closeproductTagDetail() {
                this.productTagDetailShow = false;
            },
            saveproductTagDetail() {
                if (this.productTagDetail['productid'] == '' || parseInt(this.productTagDetail['productid']) <= 0) {
                    MessageTip.warnmsg('请选择SKU');
                    return;
                }
                if (this.productTagDetail['tagid'] == '' || parseInt(this.productTagDetail['tagid']) <= 0) {
                    MessageTip.warnmsg('请选择标签');
                    return;
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{producttag(o:" + JSON.stringify(this.productTagDetail) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.productTagDetail = {
                            id: '',
                            productid: '',
                            sku: '',
                            mpn: '',
                            tagname: '',
                            tagid: ''
                        };
                        this.productTagDetailShow = false;
                        this.loadProductTagData(1);
                        MessageTip.successmsg('保存成功');
                    } else {
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            delProductTag() {
                this.$confirm.require({
                    message: '确认要删除?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        if (!this.selectedProductTagRow || this.selectedProductTagRow.length == 0) {
                            MessageTip.warnmsg('请选择要删除的数据');
                            return;
                        }
                        if (this.selectedProductTagRow.length > 0) {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                                "delete{producttaglist(o:" + JSON.stringify(this
                                    .selectedProductTagRow) +
                                "){id errmsg}}"
                            ).then(res => {
                                if (res.errcode == "0") {
                                    this.selectedProductTagRow = null;
                                    this.loadProductTagData(1);
                                    MessageTip.successmsg('删除成功');
                                } else {
                                    MessageTip.warnmsg('删除失败');
                                }
                            });
                        }
                    },
                    reject: () => {}
                });
            },
            loadProductTagData(v_page) {
                var tagidstr = "";
                for (var i = 0; i < this.queySearchTag.length; i++) {
                    if (tagidstr == "") {
                        tagidstr = this.queySearchTag[i]['code'];
                    } else {
                        tagidstr = tagidstr + "," + this.queySearchTag[i]['code'];
                    }
                }
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 100,
                    conditions: [{
                        name: 'sku',
                        value: this.queySearchKey,
                        operation: 'like'
                    }, {
                        name: 'tagid',
                        value: tagidstr,
                        operation: 'in'
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{producttaglist(where:" + JSON.stringify(listwhere) +
                    "){id creater createtime productname sku mpn tagname tagid }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.allproducttags = jsonData.data.producttaglist;
                        this.allProductRecords = jsonData.page.allrecord;
                        this.allProductFirst = 100 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '690',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;
                        if (CommonJs.userOwerAppId(this.usermoduleauth, 'CalculateProductCost')) {
                            this.showcalculateProductCost = true;
                        }
                        if (CommonJs.userOwerAppId(this.usermoduleauth, 'ProductTag')) {
                            this.showProductTag = true;
                        }
                    }
                });
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'productdirectoryid',
                        value: this.productselectdirectoryid,
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'name,mpn,sku',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{productlist(where:" + JSON.stringify(listwhere) +
                    "){id creater createtime boxnumber buyer colorname  costprice  grossweight height length mpn name netweight packingnumber packingsize packingway price productdirectory producttype size sku taxrebates unitname width imageurl issuite productstatusname auth{canedit authownerid} parts{mpn sku name matching}}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.products = jsonData.data.productlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            loadMenuTree(v_parentId, v_nodes) {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: v_parentId,
                        operation: '='
                    }, {
                        name: 'treetype',
                        value: 'product',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usertreemenulist(where:" + JSON.stringify(listwhere) +
                    "){key label data nodetype parentid parenturl canedit candel cannew createrid icon leaf children {key label data nodetype parentid parenturl canedit candel cannew createrid icon leaf} }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        if (v_parentId == "-1") {
                            this.nodes = jsonData.data.usertreemenulist;
                            if (this.nodes && this.nodes.length > 0) {
                                this.selectedKey = {};
                                this.selectedKey[this.nodes[0].key] = true;
                                this.expandedKey = {};
                                this.expandedKey[this.nodes[0].key] = true;
                                this.currentTeeNodeId = this.nodes[0].key;
                                //this.categoryName = this.nodes[0].label + '产品列表';
                                this.currentNode = this.nodes[0];
                                this.setTreeMenu(this.nodes[0]);
                                //this.loadData(1);
                            }
                        } else {
                            if (!v_nodes.children) v_nodes['children'] = [];
                            for (var i = 0; i < jsonData.data.usertreemenulist.length; i++) {
                                v_nodes.children.push(jsonData.data.usertreemenulist[i]);
                            }
                        }
                    }
                });
            },
            onNodeSelect(node) {
                this.currentNode = node;
                //this.categoryName = node.label + '产品列表';
                //this.selectedRow = [];
                this.setTreeMenu(node);
                //this.loadData(1);
            },
            setTreeMenu(node) {
                this.productdirectoryitems = [{
                    label: '新建根目录',
                    icon: 'pi pi-fw pi-folder',
                    command: () => {
                        this.createRootFolder();
                    }
                }];
                this.productitems = [];
                var directoryObj = {
                    label: '目录',
                    icon: 'pi pi-fw pi-folder',
                    items: [{
                        label: '新建根目录',
                        icon: 'pi pi-fw pi-folder',
                        command: () => {
                            this.createRootFolder();
                        }
                    }, {
                        separator: true
                    }],
                };
                /* var productObj = {
                    label: '产品',
                    icon: 'pi pi-fw pi-file',
                    items: []
                }; */
                if (node.cannew == '1') {
                    directoryObj.items.push({
                        label: '新建目录',
                        icon: 'pi pi-fw pi-folder',
                        command: () => {
                            this.createFolder();
                        }
                    });
                    this.productdirectoryitems.push({
                        label: '新建目录',
                        icon: 'pi pi-fw pi-folder',
                        command: () => {
                            this.createFolder();
                        }
                    });
                    directoryObj.items.push({
                        separator: true
                    });
                    /*   productObj.items.push({
                          label: '新建',
                          icon: 'pi pi-fw pi-file',
                          command: () => {
                              this.createProduct();
                          }
                      }); */
                    this.productitems.push({
                        label: '新建',
                        icon: 'pi pi-fw pi-file',
                        command: () => {
                            this.createProduct();
                        }
                    });
                    /*  productObj.items.push({
                         separator: true
                     });
                     productObj.items.push({
                         label: '新建套件',
                         icon: 'pi pi-fw pi-file',
                         command: () => {
                             this.createSuiteProduct();
                         }
                     }); */
                    this.productitems.push({
                        label: '新建套件',
                        icon: 'pi pi-fw pi-file',
                        command: () => {
                            this.createSuiteProduct();
                        }
                    });
                    /*  productObj.items.push({
                         separator: true
                     });
                     productObj.items.push({
                         label: '复制',
                         icon: 'pi pi-fw pi-file',
                         command: () => {
                             this.copyProduct();
                         }
                     }); */
                    this.productitems.push({
                        label: '复制',
                        icon: 'pi pi-fw pi-file',
                        command: () => {
                            this.copyProduct();
                        }
                    });
                    /*  productObj.items.push({
                         separator: true
                     }); */
                }
                if (node.createrid == sessionStorage.getItem("userid")) {
                    directoryObj.items.push({
                        label: '配置目录',
                        icon: 'pi pi-fw pi-folder',
                        command: () => {
                            this.editFolderAuth();
                        }
                    });
                    this.productdirectoryitems.push({
                        label: '配置目录',
                        icon: 'pi pi-fw pi-folder',
                        command: () => {
                            this.editFolderAuth();
                        }
                    });
                    directoryObj.items.push({
                        separator: true
                    });
                }
                if (node.canedit == '1') {
                    directoryObj.items.push({
                        label: '编辑目录',
                        icon: 'pi pi-fw pi-folder',
                        command: () => {
                            this.editFolder();
                        }
                    });
                    this.productdirectoryitems.push({
                        label: '编辑目录',
                        icon: 'pi pi-fw pi-folder',
                        command: () => {
                            this.editFolder();
                        }
                    });
                    directoryObj.items.push({
                        separator: true
                    });
                }
                if (node.candel == '1') {
                    directoryObj.items.push({
                        label: '删除',
                        icon: 'pi pi-fw pi-trash',
                        command: () => {
                            this.delFolder();
                        }
                    });
                    this.productdirectoryitems.push({
                        label: '删除',
                        icon: 'pi pi-fw pi-trash',
                        command: () => {
                            this.delFolder();
                        }
                    });

                    /*   productObj.items.push({
                          label: '删除',
                          icon: 'pi pi-fw pi-trash',
                          command: () => {
                              this.delProduct();
                          }
                      }); */
                    this.productitems.push({
                        label: '删除',
                        icon: 'pi pi-fw pi-trash',
                        command: () => {
                            this.delProduct();
                        }
                    });
                }
                this.items = [];
                console.log('directoryObj:');
                console.log(directoryObj);
                /*  console.log('productObj:');
                 console.log(productObj); */
                this.items.push(directoryObj);
                /*  if (productObj.items.length > 0) {
                     this.items.push(productObj);
                 } */
                console.log(this.items);
            },
            onNodeExpand(node) {
                console.log('onNodeExpand');
                console.log(node);
                if (node.nodetype == "1" && (!node.children || node.children.length == 0)) {
                    this.loadMenuTree(node.key, node);
                }
            },
            onCreateFoldNodeSelect(node) {
                console.log('onCreateFoldNodeSelect:');
                console.log(this.childFoldParentSelect);
                this.childFoldParentNode = node;
            },
            saveProductCost() {
                if (!this.selectedRow || this.selectedRow.length != 1) {
                    MessageTip.warnmsg('数据异常，找不到当前选择的产品');
                    return;
                }
                console.log('saveProductCost....');
                var allchildelements = this.$refs.universalpage2.getAllChildElements();
                var allmainelements = this.$refs.universalpage2.getAllMainElements();
                var originaldata = this.$refs.universalpage2.getoriginalData();
                console.log(allchildelements);
                console.log(originaldata);
                var productcostfixarg = [];
                var productcostarg = originaldata['productcost']['costarg'];
                for (var k2 = 0; k2 < allchildelements.length; k2++) {
                    if (allchildelements[k2]['name'] == 'costfixarg') {
                        productcostfixarg = allchildelements[k2]['rows'];
                    }
                }
                console.log(productcostarg);
                var isNewData = false;
                for (var k6 = 0; k6 < allmainelements.length; k6++) {
                    if (allmainelements[k6]['name'] == 'b2bprice') {
                        isNewData = true;
                        for (var k = 0; k < productcostarg.length; k++) {
                            if (productcostarg[k]['argname'] == 'b2bprice') {
                                productcostarg[k]['argval'] = allmainelements[k6]['val'];
                                isNewData = false;
                                break;
                            }
                        }
                        if (isNewData) {
                            productcostarg.push({
                                id: '-1',
                                argname: 'b2bprice',
                                argval: allmainelements[k6]['val'],
                                parentid: this.selectedRow[0].id
                            });
                        }
                    }
                    if (allmainelements[k6]['name'] == 'amazonprice') {
                        isNewData = true;
                        for (var k1 = 0; k1 < productcostarg.length; k1++) {
                            if (productcostarg[k1]['argname'] == 'amazonprice') {
                                productcostarg[k1]['argval'] = allmainelements[k6]['val'];
                                isNewData = false;
                                break;
                            }
                        }
                        if (isNewData) {
                            productcostarg.push({
                                id: '-1',
                                argname: 'amazonprice',
                                argval: allmainelements[k6]['val'],
                                parentid: this.selectedRow[0].id
                            });
                        }
                    }
                    if (allmainelements[k6]['name'] == 'expressage') {
                        isNewData = true;
                        for (var k3 = 0; k3 < productcostarg.length; k3++) {
                            if (productcostarg[k3]['argname'] == 'expressage') {
                                productcostarg[k3]['argval'] = allmainelements[k6]['val'];
                                isNewData = false;
                                break;
                            }
                        }
                        if (isNewData) {
                            productcostarg.push({
                                id: '-1',
                                argname: 'expressage',
                                argval: allmainelements[k6]['val'],
                                parentid: this.selectedRow[0].id
                            });
                        }
                    }
                    if (allmainelements[k6]['name'] == 'alloceanfreight') {
                        isNewData = true;
                        for (var k4 = 0; k4 < productcostarg.length; k4++) {
                            if (productcostarg[k4]['argname'] == 'alloceanfreight') {
                                productcostarg[k4]['argval'] = allmainelements[k6]['val'];
                                isNewData = false;
                                break;
                            }
                        }
                        if (isNewData) {
                            productcostarg.push({
                                id: '-1',
                                argname: 'alloceanfreight',
                                argval: allmainelements[k6]['val'],
                                parentid: this.selectedRow[0].id
                            });
                        }
                    }
                    if (allmainelements[k6]['name'] == 'purchaseprice') {
                        isNewData = true;
                        for (var k7 = 0; k7 < productcostarg.length; k7++) {
                            if (productcostarg[k7]['argname'] == 'purchaseprice') {
                                productcostarg[k7]['argval'] = allmainelements[k6]['val'];
                                isNewData = false;
                                break;
                            }
                        }
                        if (isNewData) {
                            productcostarg.push({
                                id: '-1',
                                argname: 'purchaseprice',
                                argval: allmainelements[k6]['val'],
                                parentid: this.selectedRow[0].id
                            });
                        }
                    }
                }
                if (CommonJs.userOwerAppId(this.usermoduleauth, 'AdminCalculateProductCost')) {
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "mutation{productcostfixarglist(o:" + JSON.stringify(productcostfixarg) +
                        "){id}}"
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.closeMaximizable2();
                        }
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{productcostarglist(o:" + JSON.stringify(productcostarg) +
                    "){id}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.closeMaximizable2();
                    }
                });
            },
            closeMaximizable2() {
                this.displayMaximizable2 = false;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            createRootFolder() {
                this.foldTitle = '新建根目录';
                this.rootFoldName = '';
                this.foldId = '-1';
                this.displayRootFold = true;
            },
            createFolder() {
                this.foldTitle = '新建目录';
                this.foldId = '-1';
                this.childFoldName = '';
                this.childFoldParentNode = null;
                this.childFoldParentSelect = null;
                this.displayChildFold = true;
            },
            editFolder() {
                if (this.currentNode) {
                    setTimeout(() => {
                        this.foldId = this.currentNode.key;
                        if (parseInt(this.currentNode.parentid) > 0) {
                            this.foldTitle = '编辑目录';
                            this.childFoldName = this.currentNode.label;
                            this.childFoldParentSelect = {};
                            this.childFoldParentSelect[this.currentNode.parentid] = true;
                            console.log(this.childFoldParentSelect);
                            this.displayChildFold = true;
                        } else {
                            this.foldTitle = '编辑根目录';
                            this.rootFoldName = this.currentNode.label;
                            this.childFoldParentNode = null;
                            this.childFoldParentSelect = null;
                            this.displayRootFold = true;
                        }
                    }, 50);
                }
            },
            delFolder() {
                if (this.currentNode) {
                    var delData = {
                        id: this.currentNode.key
                    };
                    this.confirm.require({
                        message: '确认要删除' + this.currentNode.label + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{productdirectory(o:' + JSON.stringify(delData) +
                                '){id}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.nodes.length; i++) {
                                        if (this.nodes[i].key == this.currentNode.key) {
                                            this.nodes.splice(i, 1);
                                            break;
                                        } else {
                                            if (this.nodes[i]['children'] && this.nodes[i][
                                                    'children'
                                                ]
                                                .length > 0) {
                                                if (this.removeNodeById(this.currentNode.key, this
                                                        .nodes[i][
                                                            'children'
                                                        ])) {
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {}
                    });
                }
            },
            editFolderAuth() {
                if (this.currentNode) {
                    this.foldTitle = this.currentNode.label + '目录配置';
                    var listwhere = {
                        pageindex: 1,
                        pagesize: 50,
                        conditions: [{
                            name: 'productdirectoryid',
                            value: this.currentNode.key,
                            operation: '='
                        }]
                    };
                    this.selectedProductAuthRow = null;
                    this.productauth = [];
                    this.delproductauth = [];
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "{productdirectoryauthlist(where:" + JSON.stringify(listwhere) +
                        "){id authownerid includechild candel canedit cannew canquery productdirectoryid authowner productdirectory }}"
                    ).then(res => {
                        if (res.errcode == "0") {
                            var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                            this.productauth = jsonData.data.productdirectoryauthlist;
                            this.addOtherProductAuth(this.currentNode);
                        }
                    });
                    this.displayAuthFold = true;
                }
            },
            addOtherProductAuth(v_currentNode) {
                var dictCacheData = sessionStorage.getItem('userdict');
                if (!dictCacheData || dictCacheData && dictCacheData.length == 0) {
                    dictCacheData = sessionStorage.getItem('dictionaryuser');
                }
                if (dictCacheData && dictCacheData.length > 0) {
                    var allUser = JSON.parse(dictCacheData);
                    for (var i = 0; i < allUser.length; i++) {
                        var find = false;
                        for (var k = 0; k < this.productauth.length; k++) {
                            if (allUser[i]['code'] == this.productauth[k]['authownerid']) {
                                find = true;
                                break;
                            }
                        }
                        if (!find) {
                            this.productauth.push({
                                id: '',
                                authownerid: allUser[i]['code'],
                                includechild: false,
                                candel: false,
                                canedit: false,
                                cannew: false,
                                canquery: false,
                                productdirectoryid: v_currentNode.key,
                                authowner: allUser[i]['name'],
                                productdirectory: v_currentNode.label,
                            });
                        }
                    }
                }
            },
            closeAuthFold() {
                this.allCanquery = false;
                this.allCanedit = false;
                this.allCannew = false;
                this.allCandel = false;
                this.allIncludechild = false;
                this.displayAuthFold = false;
            },
            createAuthSet() {
                this.productauth.push({
                    id: "-1",
                    authownerid: "",
                    candel: false,
                    canedit: false,
                    cannew: false,
                    canquery: false,
                    productdirectoryid: this.currentNode.key,
                    authowner: "",
                    productdirectory: this.currentNode.label
                });
            },
            delAuthSet() {
                if (!this.selectedProductAuthRow || this.selectedProductAuthRow.length == 0) {
                    MessageTip.warnmsg('请选择要移除的数据');
                    return;
                }
                for (var k1 = 0; k1 < this.selectedProductAuthRow.length; k1++) {
                    this.delproductauth.push({
                        "id": this.selectedProductAuthRow[k1]["id"],
                    });
                    for (var k2 = 0; k2 < this.productauth.length; k2++) {
                        if (this.selectedProductAuthRow[k1]["id"] == this.productauth[k2]["id"]) {
                            this.productauth.splice(k2, 1);
                            break;
                        }
                    }
                }
            },
            saveAuthFold() {
                console.log('saveAuthFold');
                console.log(this.productauth);
                console.log(this.delproductauth);
                if (this.productauth.length > 0) {
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "mutation{productdirectoryauthlist(o:" + JSON.stringify(this.productauth) +
                        "){id}}"
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.closeAuthFold();
                        }
                    });
                }
                if (this.delproductauth.length > 0) {
                    var delData = [];
                    for (var i = 0; i < this.delproductauth.length; i++) {
                        if (parseInt(this.delproductauth[i].id) > 0) {
                            delData.push(this.delproductauth[i]);
                        }
                    }
                    if (delData.length > 0) {
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "delete{productdirectoryauthlist(o:" + JSON.stringify(delData) +
                            "){id}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                this.closeAuthFold();
                            }
                        });
                    }
                }
            },
            removeNodeById(v_id, v_nodes) {
                for (var i = 0; i < v_nodes.length; i++) {
                    if (v_nodes[i].key == v_id) {
                        v_nodes.splice(i, 1);
                        return true;
                    } else {
                        if (v_nodes[i]['children'] && v_nodes[i]['children'].length > 0) {
                            if (this.removeNodeById(v_id, v_nodes[i]['children'])) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            opencalculateProductCost() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要成本核算的产品');
                    return;
                }
                if (this.selectedRow.length != 1) {
                    MessageTip.warnmsg('请选择一个要成本核算的产品');
                    return;
                }
                this.costsubject = this.selectedRow[0].name + "成本核算";
                this.currentRow2 = {
                    isCopy: false,
                    bindpage: 'productcost',
                    id: this.selectedRow[0].id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                };
                this.displayMaximizable2 = true;
            },
            copyProduct() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要复制的产品');
                    return;
                }
                if (this.selectedRow.length != 1) {
                    MessageTip.warnmsg('请选择一个要复制的产品');
                    return;
                }
                this.noLoadChild = [];
                this.pageJsonConfig = this.productSuitPageJson;
                if (this.selectedRow[0].producttype == 1) {
                    this.noLoadChild = ['parts'];
                    this.pageJsonConfig = this.productPageJson;
                }
                this.productType = this.selectedRow[0].producttype;
                this.readOnly = false;
                this.recordsubject = "复制产品" + this.selectedRow[0].name;
                this.currentRow = {
                    isCopy: true,
                    bindpage: 'product',
                    id: this.selectedRow[0].id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                };
                this.displayMaximizable = true;
            },
            createProduct() {
                this.productType = 1;
                this.pageJsonConfig = this.productPageJson;
                this.currentRow = {
                    bindpage: 'product',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                };
                this.noLoadChild = ['parts'];
                this.recordsubject = '新增产品';
                this.displayMaximizable = true;
            },
            createSuiteProduct() {
                this.productType = 2;
                this.pageJsonConfig = this.productSuitPageJson;
                this.noLoadChild = [];
                this.recordsubject = '新增套件产品';
                this.currentRow = {
                    bindpage: 'product',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                };
                this.displayMaximizable = true;
            },
            saveBefore(v_data) {
                console.log('saveBefore1:');
                if (v_data['id'] == '' || parseInt(v_data['id']) <= 0) {
                    v_data['producttype'] = this.productType;
                }
                console.log(v_data);
                return v_data;
            },
            openProduct(v_data) {
                this.noLoadChild = [];
                this.pageJsonConfig = this.productSuitPageJson;
                if (v_data.producttype == 1) {
                    this.noLoadChild = ['parts'];
                    this.pageJsonConfig = this.productPageJson;
                }
                this.productType = v_data.producttype;
                this.readOnly = true;
                for (var i = 0; i < v_data.auth.length; i++) {
                    if (v_data.auth[i].canedit == '1' && v_data.auth[i].authownerid == sessionStorage.getItem(
                            "userid")) {
                        this.readOnly = false;
                        break;
                    }
                }
                this.recordsubject = v_data.name;
                this.currentRow = {
                    bindpage: 'product',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                };
                this.displayMaximizable = true;
            },
            delProduct() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要删除的产品');
                    return;
                }
                var delRecord = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    delRecord.push({
                        "id": this.selectedRow[k1]["id"],
                    });
                }
                if (delRecord.length > 0) {
                    this.butLoading = true;
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "delete{productlist(o:" + JSON.stringify(delRecord) +
                        "){id}}"
                    ).then(res => {
                        this.butLoading = false;
                        if (res.errcode == "0") {
                            this.selectedRow = null;
                            this.loadData(1);
                            MessageTip.successmsg('删除成功');
                        } else {
                            MessageTip.warnmsg('删除失败');
                        }
                    });
                }
            },
            saveRootFold() {
                if (this.rootFoldName == '') {
                    MessageTip.warnmsg('请输入目录名称');
                    return;
                }
                this.saveFold(this.rootFoldName, -1, this.foldId);
            },
            saveChildFold() {
                if (this.childFoldName == '') {
                    MessageTip.warnmsg('请输入目录名称');
                    return;
                }
                console.log(this.childFoldParentNode);
                if (!this.childFoldParentSelect) {
                    MessageTip.warnmsg('请选择父目录');
                    return;
                }
                var parentId = "";
                for (var key in this.childFoldParentSelect) {
                    parentId = key;
                    break;
                }
                this.saveFold(this.childFoldName, parentId, this.foldId);
            },
            saveFold(v_name, v_parentid, v_id) {
                var rootFoldData = {
                    id: v_id,
                    name: v_name,
                    parentdirectoryid: v_parentid,
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{productdirectory(o:" + JSON.stringify(rootFoldData) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        var newNode = {
                            key: jsonData.data.productdirectory.id,
                            label: v_name,
                            data: '',
                            nodetype: '1',
                            icon: 'pi pi-fw pi-folder',
                            parentid: v_parentid,
                            parenturl: '/crmproduct/productlist',
                            leaf: true,
                            canedit: '1',
                            candel: '1',
                            cannew: '1',
                            createrid: sessionStorage.getItem("userid"),
                            children: []
                        };
                        if (v_parentid == "-1") {
                            if (parseInt(v_id) < 0) {
                                this.nodes.push(newNode);
                            }
                            this.closeRootFold();
                        } else {
                            if (this.childFoldParentNode) {
                                if (parseInt(v_id) > 0) {
                                    newNode.children = this.currentNode.children;
                                    this.removeNodeById(v_id, this.nodes);
                                }
                                if (!this.childFoldParentNode.children) this.childFoldParentNode[
                                    'children'] = [];
                                this.childFoldParentNode.children.push(newNode);
                                this.closeChildFold();
                            }
                        }
                        MessageTip.successmsg('保存成功');
                    } else {
                        MessageTip.warnmsg('保存失败');
                    }
                });
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            freshList() {
                this.displayMaximizable = false;
                this.butLoading = false;
                this.loadData(1);
            },
            closeRootFold() {
                this.displayRootFold = false;
            },
            closeChildFold() {
                this.displayChildFold = false;
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            getFilePatch(v_patch) {
                if (v_patch == '') {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            formatData(v_value) {
                if (v_value) {
                    return '是';
                } else {
                    return '否';
                }
            },
            productauthSetChange(v_flag) {
                console.log(v_flag);
                for (var i = 0; i < this.productauth.length; i++) {
                    if (v_flag == 'includechild') {
                        this.productauth[i]['includechild'] = this.allIncludechild;
                    }
                    if (v_flag == 'canquery') {
                        this.productauth[i]['canquery'] = this.allCanquery;
                    }
                    if (v_flag == 'canedit') {
                        this.productauth[i]['canedit'] = this.allCanedit;
                    }
                    if (v_flag == 'cannew') {
                        this.productauth[i]['cannew'] = this.allCannew;
                    }
                    if (v_flag == 'candel') {
                        this.productauth[i]['candel'] = this.allCandel;
                    }
                }
            },
            loadAllUser() {
                var dictCacheData = sessionStorage.getItem('userdict');
                if (!dictCacheData || dictCacheData && dictCacheData.length == 0) {
                    dictCacheData = sessionStorage.getItem('dictionaryuser');
                }
                if (dictCacheData && dictCacheData.length > 0) {
                    return;
                }
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: []
                };
                var graphql = '{userdictlist(where:' + JSON.stringify(dictlistwhere) +
                    '){name code comment}}';
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        console.log(dataObj);
                        var dictData = dataObj['userdictlist'];
                        sessionStorage.setItem('userdict', JSON.stringify(dictData));
                    }
                });
            },
            onCellEditComplete(event) {
                console.log('onCellEditComplete...');
                let {
                    data,
                    newValue,
                    field
                } = event;
                data[field] = newValue;
                console.log(data[field]);
            },
            cellEditComplete2(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod2..');
                console.log(tablename);
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
                this.calculateProductCost(v_elements, v_childelements);
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
                if (tablename != 'parts') {
                    return;
                }
                if (field == 'matching') {
                    var pricenum1 = 0;
                    var costpricenum1 = 0;
                    for (var i = 0; i < data.length; i++) {
                        var matchingnum2 = Number(data[i]['matching']);
                        var pricenum2 = Number(data[i]['price']);
                        var costpricenum2 = Number(data[i]['costprice']);
                        if (!isNaN(matchingnum2) && !isNaN(pricenum2)) {
                            pricenum1 = pricenum1 + (matchingnum2 * pricenum2);
                        }
                        if (!isNaN(matchingnum2) && !isNaN(costpricenum2)) {
                            costpricenum1 = costpricenum1 + (matchingnum2 * costpricenum2);
                        }
                    }
                    for (var k1 = 0; k1 < v_elements.length; k1++) {
                        if (v_elements[k1]['name'] == 'price') {
                            v_elements[k1]['val'] = pricenum1.toFixed(2);
                        }
                        if (v_elements[k1]['name'] == 'costprice') {
                            v_elements[k1]['val'] = costpricenum1.toFixed(2);
                        }
                    }
                }
            },
            expandertoggle(event, data) {
                console.log('expandertoggle...');
                console.log(data);
                this.productlistparts = data['parts'];
                this.$refs.op.toggle(event);
            },
            loadDataAfter2(v_mainelements, v_childelements, v_originalData) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                this.calculateProductCost(v_mainelements, v_childelements);
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                if (this.currentRow && this.currentRow['id'] == '-1') {
                    /* if (this.currentNode) {
                        for (var k = 0; k < v_mainelements.length; k++) {
                            if (v_mainelements[k]['name'] == 'productdirectoryid') {
                                v_mainelements[k]['val'] = this.currentNode.key;
                                break;
                            }
                        }
                    } */
                    for (var k6 = 0; k6 < this.pageJsonConfig['region_1']['left']['element'].length; k6++) {
                        var configItem1 = this.pageJsonConfig['region_1']['left']['element'][k6];
                        if (configItem1['default'] && configItem1['default'] != '') {
                            for (var k5 = 0; k5 < v_mainelements.length; k5++) {
                                if (v_mainelements[k5]['name'] == configItem1['name']) {
                                    v_mainelements[k5]['val'] = configItem1['default'];
                                    break;
                                }
                            }
                        }
                    }
                    for (var k2 = 0; k2 < v_childelements.length; k2++) {
                        if (v_childelements[k2]['name'] == 'parts') {
                            v_childelements[k2]['rows'] = [];
                        }
                        if (v_childelements[k2]['name'] == 'images') {
                            v_childelements[k2]['rows'] = [];
                        }
                        if (v_childelements[k2]['name'] == 'craft') {
                            v_childelements[k2]['rows'] = [];
                        }
                    }
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            },
            productPackingSizeBlur(allmainelements, allchildelements) {
                console.log('productPackingSizeBlur...');
                console.log(allmainelements);
                console.log(allchildelements);
                var packingsize = '';
                for (var k6 = 0; k6 < allmainelements.length; k6++) {
                    if (allmainelements[k6]['name'] == 'packingsize') {
                        packingsize = allmainelements[k6]['val'];
                        break;
                    }
                }
                if (packingsize != '' && packingsize.indexOf('*') >= 0) {
                    var packingsizearray = packingsize.split('*');
                    for (var k = 0; k < allmainelements.length; k++) {
                        if (allmainelements[k]['name'] == 'length' && packingsizearray.length > 0) {
                            allmainelements[k]['val'] = packingsizearray[0];
                        }
                        if (allmainelements[k]['name'] == 'width' && packingsizearray.length > 1) {
                            allmainelements[k]['val'] = packingsizearray[1];
                        }
                        if (allmainelements[k]['name'] == 'height' && packingsizearray.length > 2) {
                            allmainelements[k]['val'] = packingsizearray[2];
                        }
                    }
                }
            },
            productlengthBlur(allmainelements, allchildelements) {
                console.log('productlengthBlur...');
                console.log(allmainelements);
                console.log(allchildelements);
                var length = 0;
                var width = 0;
                var height = 0;
                for (var k6 = 0; k6 < allmainelements.length; k6++) {
                    if (allmainelements[k6]['name'] == 'length') {
                        length = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'width') {
                        width = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'height') {
                        height = Number(allmainelements[k6]['val']);
                    }
                }
                if (!isNaN(length) && !isNaN(width) && !isNaN(height) && length > 0 && width > 0 && height > 0) {
                    for (var k = 0; k < allmainelements.length; k++) {
                        if (allmainelements[k]['name'] == 'packingsize') {
                            allmainelements[k]['val'] = length + '*' + width + '*' + height;
                            break;
                        }
                    }
                }
            },
            productpriceBlur(allmainelements, allchildelements) {
                console.log('productpriceBlur...');
                console.log(allmainelements);
                console.log(allchildelements);
                var price = 0;
                var boxnumber = 0;
                for (var k6 = 0; k6 < allmainelements.length; k6++) {
                    if (allmainelements[k6]['name'] == 'price') {
                        price = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'boxnumber') {
                        boxnumber = Number(allmainelements[k6]['val']);
                    }
                }
                if (!isNaN(price) && !isNaN(boxnumber)) {
                    for (var k = 0; k < allmainelements.length; k++) {
                        if (allmainelements[k]['name'] == 'costprice') {
                            allmainelements[k]['val'] = parseFloat((price * boxnumber).toFixed(2));
                            break;
                        }
                    }
                }
            },
            b2bpriceBlur(allmainelements, allchildelements) {
                this.calculateProductCost(allmainelements, allchildelements);
            },
            expressageBlur(allmainelements, allchildelements) {
                this.calculateProductCost(allmainelements, allchildelements);
            },
            amazonpriceBlur(allmainelements, allchildelements) {
                this.calculateProductCost(allmainelements, allchildelements);
            },
            alloceanfreightBlur(allmainelements, allchildelements) {
                this.calculateProductCost(allmainelements, allchildelements);
            },
            purchasepriceBlur(allmainelements, allchildelements) {
                this.calculateProductCost(allmainelements, allchildelements);
            },
            calculateProductCost(allmainelements, allchildelements) {
                console.log('calculateProductCost...');
                console.log(allmainelements);
                console.log(allchildelements);
                var purchaseprice = 0;
                var purchasepriceArg = 0;
                var boxnumber = 0;
                var fOBExchangeRateArg = 0;
                var fOBPriceArg = 0;
                var alloceanfreight = 0;
                var tariffArg = 0;
                var uloadCostArg = 0;
                var storageCostArg = 0;
                var volume = 0;
                var platformFeeArg = 0;
                var b2bprice = 0;
                var backtrackFreeArg = 0;
                var advertisingFeeArg = 0;
                var amazonprice = 0;
                var refundArg = 0;
                var expressage = 0;
                for (var k6 = 0; k6 < allmainelements.length; k6++) {
                    if (allmainelements[k6]['name'] == 'purchaseprice') {
                        purchaseprice = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'boxnumber') {
                        boxnumber = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'alloceanfreight') {
                        alloceanfreight = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'volume') {
                        volume = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'b2bprice') {
                        b2bprice = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'amazonprice') {
                        amazonprice = Number(allmainelements[k6]['val']);
                    }
                    if (allmainelements[k6]['name'] == 'expressage') {
                        expressage = Number(allmainelements[k6]['val']);
                    }
                }
                for (var k2 = 0; k2 < allchildelements.length; k2++) {
                    if (allchildelements[k2]['name'] == 'costfixarg') {
                        for (var k1 = 0; k1 < allchildelements[k2]['rows'].length; k1++) {
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'PurchasePrice') {
                                purchasepriceArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'FOBExchangeRate') {
                                fOBExchangeRateArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'FOBPrice') {
                                fOBPriceArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'Tariff') {
                                tariffArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'UloadCost') {
                                uloadCostArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'StorageCost') {
                                storageCostArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'PlatformFee') {
                                platformFeeArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'BacktrackFree') {
                                backtrackFreeArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'AdvertisingFee') {
                                advertisingFeeArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                            if (allchildelements[k2]['rows'][k1]['argname'] == 'Refund') {
                                refundArg = Number(allchildelements[k2]['rows'][k1]['argval']);
                            }
                        }
                    }
                }

                var fobcostprice = 0;
                if (!isNaN(purchaseprice) && !isNaN(purchasepriceArg) && !isNaN(boxnumber) && !isNaN(
                        fOBExchangeRateArg) && boxnumber > 0 && fOBExchangeRateArg > 0) {
                    fobcostprice = Number(((purchaseprice / 1.13 * boxnumber + purchasepriceArg) / (fOBExchangeRateArg *
                        boxnumber)).toFixed(2));
                }
                var fobprice = 0;
                if (!isNaN(fOBPriceArg)) {
                    fobprice = Number((fobcostprice * (1 + fOBPriceArg)).toFixed(2));
                }
                var oceanfreight = 0;
                if (!isNaN(alloceanfreight) && !isNaN(boxnumber) && boxnumber > 0) {
                    oceanfreight = Number((alloceanfreight / boxnumber).toFixed(2));
                }
                var tariff = 0;
                if (!isNaN(tariffArg)) {
                    tariff = Number((fobcostprice * tariffArg * 0.25).toFixed(2));
                }
                var uloadcost = 0;
                if (!isNaN(uloadCostArg) && !isNaN(boxnumber) && boxnumber > 0) {
                    uloadcost = Number((uloadCostArg / boxnumber).toFixed(2));
                }
                var storagecost = 0;
                if (!isNaN(storageCostArg) && !isNaN(volume)) {
                    storagecost = Number((storageCostArg * volume * 3).toFixed(2));
                }
                var outboundcost = Number((fobprice + oceanfreight + tariff + uloadcost + storagecost).toFixed(2));
                var platformfee = 0;
                if (!isNaN(platformFeeArg) && !isNaN(b2bprice)) {
                    platformfee = Number((platformFeeArg * b2bprice).toFixed(2));
                }
                var backtrackfree = 0;
                if (!isNaN(backtrackFreeArg) && !isNaN(b2bprice)) {
                    backtrackfree = Number((backtrackFreeArg * b2bprice).toFixed(2));
                }
                var salesreceipts = Number((b2bprice - platformfee - backtrackfree).toFixed(2));
                var b2bprofit = Number((salesreceipts - outboundcost).toFixed(2));
                var wholesaleprofit = 0;
                if (!isNaN(b2bprice) && b2bprice > 0) {
                    wholesaleprofit = Number((b2bprofit / b2bprice).toFixed(2));
                }
                var advertisingfee = 0;
                if (!isNaN(advertisingFeeArg) && !isNaN(amazonprice)) {
                    advertisingfee = Number((amazonprice * advertisingFeeArg).toFixed(2));
                }
                var refund = 0;
                if (!isNaN(refundArg) && !isNaN(amazonprice)) {
                    refund = Number((amazonprice * refundArg).toFixed(2));
                }
                var amazonfree = 0;
                if (!isNaN(amazonprice)) {
                    if (amazonprice > 200) {
                        amazonfree = Number(((amazonprice - 200) * 0.1 + 30).toFixed(2));
                    } else {
                        amazonfree = Number((amazonprice * 0.15).toFixed(2));
                    }
                }
                var amazongrossprofit = 0;
                if (!isNaN(amazonprice) && !isNaN(b2bprice) && !isNaN(expressage) && amazonprice > 0) {
                    amazongrossprofit = Number(((amazonprice - b2bprice - expressage - amazonfree) / amazonprice)
                        .toFixed(2));
                }

                for (var k = 0; k < allmainelements.length; k++) {
                    if (allmainelements[k]['name'] == 'fobcostprice') {
                        allmainelements[k]['val'] = fobcostprice;
                    }
                    if (allmainelements[k]['name'] == 'fobprice') {
                        allmainelements[k]['val'] = fobprice;
                    }
                    if (allmainelements[k]['name'] == 'oceanfreight') {
                        allmainelements[k]['val'] = oceanfreight;
                    }
                    if (allmainelements[k]['name'] == 'tariff') {
                        allmainelements[k]['val'] = tariff;
                    }
                    if (allmainelements[k]['name'] == 'uloadcost') {
                        allmainelements[k]['val'] = uloadcost;
                    }
                    if (allmainelements[k]['name'] == 'storagecost') {
                        allmainelements[k]['val'] = storagecost;
                    }
                    if (allmainelements[k]['name'] == 'outboundcost') {
                        allmainelements[k]['val'] = outboundcost;
                    }
                    if (allmainelements[k]['name'] == 'backtrackfree') {
                        allmainelements[k]['val'] = backtrackfree;
                    }
                    if (allmainelements[k]['name'] == 'platformfee') {
                        allmainelements[k]['val'] = platformfee;
                    }
                    if (allmainelements[k]['name'] == 'salesreceipts') {
                        allmainelements[k]['val'] = salesreceipts;
                    }
                    if (allmainelements[k]['name'] == 'b2bprofit') {
                        allmainelements[k]['val'] = b2bprofit;
                    }
                    if (allmainelements[k]['name'] == 'wholesaleprofit') {
                        allmainelements[k]['val'] = wholesaleprofit;
                    }
                    if (allmainelements[k]['name'] == 'advertisingfee') {
                        allmainelements[k]['val'] = advertisingfee;
                    }
                    if (allmainelements[k]['name'] == 'refund') {
                        allmainelements[k]['val'] = refund;
                    }
                    if (allmainelements[k]['name'] == 'amazonfree') {
                        allmainelements[k]['val'] = amazonfree;
                    }
                    if (allmainelements[k]['name'] == 'amazongrossprofit') {
                        allmainelements[k]['val'] = amazongrossprofit;
                    }
                }
            },
            getExcelFilePatch() {
                location.href = '/calf/plat/file/' + this.excelFilePatch + '?appkey=' + sessionStorage.getItem(
                    "appkey");
                return;
            },
            exportProductCost() {
                var exportInput = {
                    templateId: '325349009821765',
                    appKey: sessionStorage.getItem("appkey"),
                    conditions: [{
                        schemaName: 'productcost',
                        conditionValue: [{
                            name: 'export',
                            value: '',
                            operation: '='
                        }],
                    }],
                };
                this.excelFilePatch = '';
                this.importFinish = false;
                this.importExcelDisplay = true;
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid")).post(
                        '/calf/plat/DecisionAnalysisExport?appkey=' + sessionStorage.getItem("appkey"), exportInput)
                    .then(
                        res => {
                            console.log(res);
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('exportProductCost:');
                                console.log(retObj);
                                this.excelFilePatch = retObj.filepatch;
                                this.importFinish = true;
                            } else {
                                this.importExcelDisplay = false;
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            inputdropSelectAfter(v_id, v_data) {
                console.log('inputdropSelectAfter....');
                console.log(v_data);
                if (v_id == 'tblproductsampleid') {
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        '{productsample(id:"' + v_data['id'] +
                        '",workflowid:"-1",boxid:"-1",flowguid:""){boxnumber buyerid color costprice grossweight height length mpn name netweight packingnumber packingsize packingway price productdirectoryid remark size taxrebates unit width ownedstoreid factoryid sampleno factorysku quantity productdirectory unitname colorname buyer imageurl ownedstorename factoryname factorycode id images{ imgetype name parentid url id createrid creater createtime } craft{ cname craftdictid craftcontent parentid id createrid creater createtime }}}'
                    ).then(res => {
                        this.loading = false;
                        if (res.errcode == "0") {
                            var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                            var productsampleobj = jsonData.data.productsample;
                            var allmainelements = this.$refs.universalpage.getAllMainElements();
                            var allchildelements = this.$refs.universalpage.getAllChildElements();
                            var noshowelements = this.$refs.universalpage.getNoShowElements();
                            var isInsert = false;
                            if (this.currentRow['id'] == '' || this.currentRow['id'] == '-1') {
                                isInsert = true;
                            }
                            for (var pro in productsampleobj) {
                                if (pro != 'images' && pro != 'craft' && pro != 'id') {
                                    for (var k = 0; k < allmainelements.length; k++) {
                                        if (pro != 'color' && pro != 'colorname' && allmainelements[k][
                                                'name'
                                            ] ==
                                            pro) {
                                            allmainelements[k]['val'] = productsampleobj[pro];
                                        }
                                        if (isInsert && (pro == 'color' || pro == 'colorname')) {
                                            if (allmainelements[k]['name'] == pro)
                                                allmainelements[k]['val'] = productsampleobj[pro];
                                        }
                                    }
                                }
                            }
                            for (var pro2 in productsampleobj) {
                                if (pro2 != 'images' && pro2 != 'craft' && pro2 != 'id') {
                                    for (var pro3 in noshowelements) {
                                        if (pro2 != 'color' && pro2 != 'colorname' && pro3 == pro2) {
                                            noshowelements[pro3] = productsampleobj[pro2];
                                        }
                                        if (isInsert && (pro2 == 'color' || pro2 == 'colorname') && pro3 ==
                                            pro2) {
                                            noshowelements[pro3] = productsampleobj[pro2];
                                        }
                                    }
                                }
                            }
                            for (var k2 = 0; k2 < allchildelements.length; k2++) {
                                if (allchildelements[k2]['name'] == 'images') {
                                    for (var i = 0; i < allchildelements[k2].rows.length; i++) {
                                        if (parseInt(allchildelements[k2].rows[i]["id"]) > 0) {
                                            allchildelements[k2].rows[i]["isdel"] = '1';
                                            allchildelements[k2].delrows.push(allchildelements[k2].rows[i]);
                                        }
                                    }
                                    allchildelements[k2]['rows'] = [];
                                    for (var i2 = 0; i2 < productsampleobj['images'].length; i2++) {
                                        productsampleobj['images'][i2]['id'] = "-" + productsampleobj['images'][
                                            i2
                                        ]['id'];
                                        allchildelements[k2]['rows'].push(productsampleobj['images'][i2]);
                                    }
                                }
                                if (allchildelements[k2]['name'] == 'craft') {
                                    for (var i3 = 0; i3 < allchildelements[k2].rows.length; i3++) {
                                        if (parseInt(allchildelements[k2].rows[i3]["id"]) > 0) {
                                            allchildelements[k2].rows[i3]["isdel"] = '1';
                                            allchildelements[k2].delrows.push(allchildelements[k2].rows[i3]);
                                        }
                                    }
                                    allchildelements[k2]['rows'] = [];
                                    for (var i4 = 0; i4 < productsampleobj['craft'].length; i4++) {
                                        productsampleobj['craft'][i4]['id'] = "-" + productsampleobj[
                                            'craft'][i4]['id'];
                                        allchildelements[k2]['rows'].push(productsampleobj['craft'][i4]);
                                    }
                                }
                            }
                            setTimeout(() => {
                                this.$refs.universalpage.$refs.buyerid.freshSelect(productsampleobj[
                                    'buyerid']);
                                this.$refs.universalpage.$refs.factoryid.freshSelect(productsampleobj[
                                    'factoryid']);
                                this.$refs.universalpage.$refs.ownedstoreid.freshSelect(
                                    productsampleobj['ownedstoreid']);
                                this.$refs.universalpage.$refs.taxrebates.freshSelect(productsampleobj[
                                    'taxrebates']);
                                this.$refs.universalpage.$refs.unit.freshSelect(productsampleobj[
                                    'unit']);
                                if (isInsert) {
                                    this.$refs.universalpage.$refs.color.freshSelect(productsampleobj[
                                        'color']);
                                }
                            }, 100);
                            console.log(allmainelements);
                            console.log(noshowelements);
                        }
                    });
                }
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
            CalfInputDropdown,
            CalfDropdown,
            CalfMultiSelect,
            Image,
            CalfTreeDropdown
        }
    }
</script>
<style lang="scss" scoped>
    .product-image {
        width: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }

    .orders-subtable {
        padding: 1rem;
    }
</style>